<template>
  <div class="reprint-article-container">
    <header class="header">
      <van-nav-bar
          title="转载文章"
          left-arrow
          @click-left="handleBlack"
          safe-area-inset-top
      >
      </van-nav-bar>
    </header>
    <article class="article">
      <div class="reprint">
        <span>文章地址</span>
        <div class="url-info">
          <van-field
              v-model="reprintUrl"
              rows="2"
              autosize
              type="textarea"
              placeholder="请输入你要转载的文章地址"
              show-word-limit
          />
          <div style="font-size: 14px;width: 50px;color: #289fff;">
            <span @click="handleGenerate">解析</span>
          </div>
        </div>
        <div class="supper-platform">支持平台:<span @click="handleOpenBrowser">公众号文章</span></div>
      </div>
      <div class="article_info" v-if="showFinish">
        <span>文章标题</span>
        <van-field
            v-model="articles.title"
            rows="2"
            autosize
            type="textarea"
            placeholder="请输入你要添加的内容"
            show-word-limit
        />
        <span>文章封面</span>
        <div class="article_img">
          <image-upload
              v-if="loading"
              :busiId="articles.articleId"
              :busiCat="busi_Cat"
              :groupId="group_Id_index"
          ></image-upload>
        </div>
      </div>
      <div class="footer">
        <van-grid clickable :column-num="2" safe-area-inset-bottom v-if="showFinish">
          <van-grid-item
          ><van-button
              round
              type="primary"
              block
              color="#FF6E01"
              @click="handleSaveArticle"
          >完成</van-button
          ></van-grid-item
          >
        </van-grid>
      </div>
    </article>
  </div>
</template>
<script>
import ImageUpload from "../../components/FileUpload/ImageUpload.vue";
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import {findNextId,getWxArticleByUrl,createArticle} from "../../api/huokeArticle";
import { uploadCoverPic} from "@/api/file/index";
import {openExternalBrowser} from "../../utils/commonUtil"
import { Toast } from 'vant';
export default {
  components: {
    ImageUpload,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      showFinish:false,
      reprintUrl:'',
      showInCard:false,
      loading: true,
      busi_Cat: "article",
      group_Id_index: "articleIndexPic",
      isRealDeleteImg: false,
      newBusiId: "",
      baseImg:'',
      articles: {
        oldId: "",
        articleId: "", //文章id
        title: "", //标题
        content: "", //内容
        countOfLikes: 0, //点赞
        countOfCollection: 0, //收藏
        cardSort:0,
        isCardRecommend:0
      },
    });

    //获取文章id
    const getArticleId = async () => {
      state.loading = false
      let data = await findNextId();
      state.articles.articleId = data;
      state.loading = true
    };
    getArticleId();
    const handleOpenBrowser=()=>{
      let url = 'https://weixin.sogou.com/'
      openExternalBrowser(url)
    }
    const handleSaveArticle = async () => {
      let params = {
        articleId:state.articles.articleId,
        title:state.articles.title,
        content:state.articles.content,
        countOfLikes: 0, //点赞
        countOfCollection: 0, //收藏
        cardSort:0,
        isCardRecommend:0
      }
      if(state.articles.title === ""){
        Toast('文章标题不能为空');
      }else {
        let result = await createArticle(params);
        console.log(result)
        if(result){
          router.replace({
            path: "/articleVisitors/visitorDetails",
            query: {
              id: state.articles.articleId,
              // myType: Number(route.query.myType) === 1 ? 3 : 0,
              // twoType: route.query.twoType,
            },
          });
        }
      }

    };
    const switchShowInCard = (str)=>{
      if(str===true){
        state.articles.isCardRecommend = 1
      }else{
        state.articles.isCardRecommend = 0
      }
    }
    const handleBlack = () => {
      router.back();
    };
    const handleGenerate= async ()=>{
      Toast.loading({
        message: '解析中...',
      });
      let data = await findNextId();
      state.articles.articleId = data;
      state.busi_Id = data;
      let res = await getWxArticleByUrl({url:state.reprintUrl})
      if (res){
        state.loading = false
        state.articles.title = res.title
        state.articles.content = res.content
        state.baseImg ="data:image/jpg;base64,"+res.headImgUrl
        uploadImg()
      }else {
        Toast.clear()
      }
    }
    //文件上传
    const uploadImg = async ()=>{
      let imgFile = await dataURLtoFile(state.baseImg,'jpg')
      let formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
      formData.append("file", imgFile); //接口需要传的参数
      formData.append("busiId", state.articles.articleId); //接口需要传的参数
      formData.append("busiCat", "article"); //接口需要传的参数
      formData.append("groupId", "articleIndexPic"); //接口需要传的参数
      uploadCoverPic(formData)
          .then((data) => {
            let item = {
              url: `${process.env.VUE_APP_WATCH_FILE_API}/${data.url}`,
              fileId: data.fileId,
            };
            state.loading = true
            state.showFinish = true
            Toast.clear()
            Toast.success('解析完成');
            console.log("上传后的海报地址===============",item);
          })
          .catch((err) => {
            console.log(err);
          });
    }
    //base64转文件
    const dataURLtoFile=(dataurl, filename)=> {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
    }
    return {
      ...toRefs(state),
      handleBlack,
      handleSaveArticle,
      switchShowInCard,
      getArticleId,
      handleGenerate,
      uploadImg,
      handleOpenBrowser,
    };
  },
};
</script>
<style lang="scss">
.reprint-article-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f1f3f6;
  //遮罩层
  //遮罩层
  .del-wrapper {
    .van-overlay {
      z-index: 102;
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .block {
        font-size: 14px;
        width: 303px;
        height: 133px;
        border-radius: 10px;
        color: #333333;
        background-color: #fff;
        > div {
          > div:nth-child(1) {
            .van-hairline {
              border-radius: 0 0 0 10px;
              padding: 0;
              color: #999999;
            }
          }
          > div:nth-child(2) {
            .van-hairline {
              border-radius: 0 0 10px 0;
              padding: 0;
            }
            [class*="van-hairline"]::after {
              border-right: none;
            }
          }
        }
        [class*="van-hairline"]::after {
          border-bottom: none;
        }
        > p {
          font-weight: 600;
          font-size: 14px;
          margin: 17px 16px;
        }
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 46px;
        }
      }
    }
  }

  .header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
  }
  .article {
    flex: 1;
    overflow-x: hidden;
    .reprint{
      padding: 16px;
      > span {
        text-align: left;
        color: rgba(0, 0, 0, 0.84);
        font-size: 16px;
        font-weight: 400;
        display: block;
        margin-bottom: 7px;
      }
      .supper-platform{
        padding-top: 10px;
        text-align: left;
        font-size: 14px;
        span{
          width: 50px;
          color: #289fff;
        }
      }
      .url-info{
        display: flex;
        align-items: center;
        >span{
          text-align: right;
        }
      }
    }
    .article_info {
      padding: 17px;
      text-align: left;
      border-radius: 5px;
      > span {
        color: rgba(0, 0, 0, 0.84);
        font-size: 16px;
        font-weight: 400;
        display: block;
        margin-bottom: 7px;
      }
      > span:nth-child(3) {
        margin-top: 30px;
      }
    }
    .article_editor {
      img{
        width: 100%;
      }
      text-align: left;
      font-size: 14px;
    }
    .star-and-con {
      padding: 0 16px;

      .van-cell {
        margin-top: 8px;
        border-radius: 4px;
      }
      .van-cell__title{
        font-size: 14px;
        font-weight: 600;
        color: black;
      }
    }
    .is-show-card{

      padding: 0 16px;
      .show-switch{
        color: black;
        font-size: 14px;
        font-weight: 600;
        padding: 0 16px;
        height: 44px;
        margin-top: 8px;
        border-radius: 4px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .van-cell {
        margin-top: 8px;
        border-radius: 4px;
      }
      .van-cell__title{
        font-size: 14px;
        font-weight: 600;
        color: black;
      }

    }
    .button_confirm {
      display: flex;
      justify-content: space-between;
      padding: 16px;

      > div {
        width: 160px;
        height: 44px;
        color: #fff;
        font-size: 14px;
        border-radius: 8px;
        text-align: center;
        line-height: 44px;
        background-color: #bfbfbf;
      }
      .button_confirm_save {
        background: linear-gradient(0deg, #ff6e01, #ff6e01);
      }
    }
    .footer {
      display: flex;
      justify-content: center;
      .van-button--round {
        border-radius: 8px;
        height: 44px;
        width: 160px;
      }
      .van-hairline {
        background-color: #f7f7f7;
      }
    }
  }
}
</style>